import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Empty from '../../../Components/Empty';
import ProductItem from './ProductItemType1';
import ArticleItem from './ArticleItemType1';

function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (products.length <= 0) {
    return <Empty />;
  }

  if (
    prefixPath.indexOf('products') >= 0 ||
    prefixPath.indexOf('sales-event') >= 0
  ) {
    return (
      <ProductGridWrapper mobile={mobile}>
        {products.map((product, i) => (
          <ProductItem
            key={product.id}
            mobile={mobile}
            product={product}
            onClick={(evt) => onItemClick(product, evt)}
          />
        ))}
        {[0, 1, 2].map((_, key) => (
          <ProductItem extraStyle="height: 0px;" />
        ))}
      </ProductGridWrapper>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper>
        {products.map((product, i) => (
          <ArticleItem key={i} item={product} url={`/article/?id=${product.id}`} />
        ))}
        {[0, 1, 2].map((_) => (
          <ArticleItem extraStyle="height: 0px;" />
        ))}
      </ArticleGridWrapper>
    );
  }
}

const ProductGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};

  & > .filler {
    width: ${(props) => (props.mobile ? '160px' : '200px')};
    height: 1px;
    margin: 10px;
    border: 1px solid red;
  }
`;

const ArticleGridWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: ${420 - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ProductGrid;